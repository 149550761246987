<template>
    <div>
        <div v-if="row.item.expected_delivery_date">
            {{ row.item.expected_delivery_date }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>